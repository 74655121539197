import { actions } from './index'
import { helpers } from '@common/index'
import { URL } from '@common/helpers'

import BizTracker from '@pc/common/tracker/bizTracker'
import { globalActionCreator } from '@pc/common/globalActions'
import axios from 'axios'
import md5 from 'md5'
import { push } from 'connected-react-router'
import {
  SECURITY_VERIFICATION_CALL_METHOD,
  AUTH_STATUS,
  SCENARIO_NUMBER_MAP,
  SECURITY_VERIFICATION_BIZ_TYPE,
} from '@pc/common/macro'
import { asyncSecurityCheck } from '@/components/Security'
import Logger, * as elementId from '@pc/config/logger'
import { store } from '@pc/store'
import { Translate } from '@pc/config/translate'
import loggerConfig from '../logger.config'
import { history } from 'umi'
import { addQueryToUrl, getApiBaseUrl } from '@pc/config/utils'
import { getSmDeviceData } from '@/components/device-sdk'
import { setSecurityCacheData } from '@/components/Security/utils'
import { fetchAlipayPlusOrder } from '~/views/CreateOrder/shared'
import { actionCreator as mainActionCreator } from '@pc/views/main/store'

const { storage } = helpers

const __isPc = () => window.location.href.includes('/pc/')

/*
 * 设置当前选择的分期数
 */
const __setCurPeriod = (curPeriod) => ({
  type: actions.SET_CUR_PERIOD,
  curPeriod,
})

/*
 * PC端
 * 设置当前
 */
export const goSetNowOperationType = (operationType) => ({
  type: actions.SET_NOW_OPREATION_TYPE,
  operationType,
})

/*
 * 保存首页banner图信息
 */
const __setFirstPageBanner = (bannerList) => ({
  type: actions.SET_FIRST_PAGE_BANNER,
  bannerList,
})

/*
 * 设置密码错误信息
 * errText {string} 错误信息
 */
export const goSetPasswordErrorText = (errText) => ({
  type: actions.SET_PASSWORD_ERROR_TEXT,
  errText,
})

/*
 * 设置密码错误信息
 * errText {string} 错误信息
 */
export const goSetPhoneNumberErrorText = (errText) => ({
  type: actions.SET_PHONE_NUMBER_ERROR_TEXT,
  errText,
})

/**
 * 设置验证码错误信息
 * errText {string} 错误信息
 * **/

export const goSetCodeErrorText = (errText) => ({
  type: actions.SET_CODE_ERROR_TEXT,
  errText,
})

/*
 * 取消订单
 */
export const goCancelPayment = () => {
  const state = store.getState()
  const appId = state.getIn(['main', 'appId']),
    refNo = state.getIn(['main', 'refNo']),
    sign = state.getIn(['main', 'sign']),
    callbackPageUrl = state.getIn(['login', 'callbackPageUrl'])
  const translate = Translate.getInstance().translate
  const saLogger = Logger.getInstance()

  return async (dispatch) => {
    dispatch(globalActionCreator.toggleLoading(true))
    const {
      data: { success, errCode },
    } = await axios.post(
      '/installment/api/json/public/openpay/user/payment/cancel.do',
      {
        appId,
        refNo,
        sign,
      },
    )
    dispatch(globalActionCreator.toggleLoading(false))

    if (success) {
      saLogger.onPageClick(elementId.CLICK_CANCEL_ORDER)
      window.location.href = `${callbackPageUrl}?appId=${appId}&refNo=${refNo}&sign=${sign}`
    } else {
      if (errCode === 'openpay.0008') {
        dispatch(
          globalActionCreator.toggleAlertDialog(true, {
            title: '',
            content: translate('取消支付失败，请重试'),
            footer: translate('我知道了'),
          }),
        )
      } else {
        window.location.href = `${callbackPageUrl}?appId=${appId}&refNo=${refNo}&sign=${sign}`
      }
    }
  }
}

/*
 * 获取订单详情
 * defaultPeriod 第三方传递的默认分期数（lazada）
 */
export const goGetInstallmentInfo = (appId, refNo, sign, defaultPeriod) => {
  return async (dispatch) => {
    dispatch(globalActionCreator.toggleLoading(true))
    const params = {}
    if (defaultPeriod) params.periods = defaultPeriod
    const {
      data: { data, success },
    } = await axios.post('/capi/openpay/public/home/installment/plan', params, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    dispatch(globalActionCreator.toggleLoading(false))

    if (success) {
      dispatch(
        __setCurPeriod(
          data.selectedItem ? String(data.selectedItem.periods) : '',
        ),
      )
      dispatch(__setShowInstallmentPlan(data.showInstallmentPlan))
      dispatch(__setInstallmentList(data.homePageInstallmentItems))
      dispatch(__setPayAmount(data.payAmount))
      dispatch(__setAdTagContent(data.adTagContent))
    }
  }
}

/*
 * 用户登录
 * phoneNumber {string} 手机号
 * password    {string} 密码
 * curPeriod   {string} 当前选择的分期数
 * 当isWhiteList为true时，代表该商户使用新流程
 * securityCheck 是否已经进行了安全组件校验，用户点击登录的情况下是 false，安全组件操作成功之后为true
 * 否则继续使用老流程
 */
export const goLoginWithPwd = ({
  phoneNumber,
  password,
  curPeriod,
  callBackPageUrl,
  securityCheck = false,
}) => {
  const translate = Translate.getInstance().translate
  const saLogger = Logger.getInstance()
  const state = store.getState()
  const countryId = state.getIn(['main', 'countryId'])
  // const isWhiteList = state.getIn(['main', 'isWhiteList'])
  const params = {
    phoneNumber,
    password,
    curPeriod,
    callBackPageUrl,
    countryId,
  }

  // 如果通过了校验 就把operationId 带上
  if (securityCheck) {
    params.riskFlowId = state.getIn(['login', 'operationId'])
  }

  // true 的情况继续登录， false 的情况就需要调起安全组件了
  if (securityCheck) {
    return (dispatch) => {
      dispatch(__loginWithPwdNew(params, translate, saLogger))
    }
  } else {
    return (dispatch) => {
      dispatch(setSecurityVerificationTempParams(params))
      dispatch(toggleSecurityVerification(true))
      saLogger.click({
        ...loggerConfig.login_security_check,
        Aku_operation_id: __isPc()
          ? SECURITY_VERIFICATION_BIZ_TYPE.PC_LOGIN
          : SECURITY_VERIFICATION_BIZ_TYPE.LOGIN,
      })
      // 上报设备信息
      dispatch(
        globalActionCreator.globalSendDeviceData({
          scenario_number: SCENARIO_NUMBER_MAP.LOGIN_SECURITY_CHECK,
        }),
      )

      dispatch(
        setSecurityVerificationCaller(SECURITY_VERIFICATION_CALL_METHOD.LOGIN),
      )
    }
  }
}

/*
 * 用户登录——新流程
 * @see http://testyapi.akulaku.com/project/568/interface/api/48919
 */
const __loginWithPwdNew = (params, translate, saLogger) => {
  const { password, phoneNumber, riskFlowId } = params
  const postData = {
    account: phoneNumber,
    password: md5(password),
    orderId: helpers.URL.getParam('orderId') || '',
    // loginSource: 2 //登录来源：openpay
    // loginWay: 'OPENPAY'
  }

  const state = store.getState()
  // const needPwdLoginGraphicCode = state.getIn([
  //   'login',
  //   'needPwdLoginGraphicCode'
  // ])

  // if (needPwdLoginGraphicCode) postData.imageCaptcha = imageCaptcha
  if (riskFlowId) postData.operationId = riskFlowId

  const showBindProtocol = state.getIn(['main', 'showBindProtocol'])
  const agreeBindProtocol = state.getIn(['login', 'agreeBindProtocol'])
  // 如果显示了绑定协议控件 登录的时候 就需要这个参数
  if (showBindProtocol) {
    postData.agreeBindProtocol = agreeBindProtocol
  }

  return async (dispatch) => {
    dispatch(globalActionCreator.toggleLoading(true))
    const smDeviceData = await getSmDeviceData()
    const { data: { success, data, errCode, errMsg } = {} } = await axios.post(
      '/capi/openpay/v2/public/client/user/password/login',
      {
        smDeviceData,
        ...postData,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )

    // 重置安全组件相关的参数
    dispatch(setSecurityVerificationTempParams({}))
    dispatch(setSecurityVerificationCaller(''))
    dispatch(setOperationId(''))
    BizTracker.click({
      cn: 17,
      sn: 390001,
      ext: {
        result: success ? 'success' : 'failed',
        failure_reason: errCode,
        login_method: 1, // 登录方式（1-点击登录 2-缓存登录）
      },
    })
    const saLogger = Logger.getInstance()
    // const loggerInfo = success
    //   ? { Aku_buttonStatus: 1 }
    //   : { Aku_buttonStatus: 0 }
    saLogger.onPageClick({
      ...loggerConfig.login,
      // ...loggerInfo,
      code: success ? '200' : errCode,
      Aku_msg: errMsg,
      Aku_channelName: 'account',
    })

    if (success) {
      setSecurityCacheData({
        loginType: data?.loginType,
        loginTime: data?.loginTime,
        riskSign: data?.riskSign,
      })
      // saLogger.setUid(data.loginInfo.uid)
      // saLogger.login(data.loginInfo.uid)
      // 登录成功上报设备信息
      dispatch(
        globalActionCreator.globalSendDeviceData({
          scenario_number: SCENARIO_NUMBER_MAP.LOGIN,
        }),
      )

      // 下单要传password
      dispatch(__savePrivateKey(md5(password)))

      const { authStatus, loginMethodToken } = data
      dispatch(globalActionCreator.toggleLoading(false))
      dispatch(
        __performAfterLogin({
          authStatus,
          loginMethodToken,
          phoneNumber,
          password,
          uid: data.uid,
        }),
      )
    } else {
      dispatch(globalActionCreator.toggleLoading(false))
      saLogger.onPageError(elementId.ERROR_LOGIN_FAIL, errMsg)
      switch (errCode) {
        case 'PROFILE.0002':
        case 'PROFILE.0025':
          dispatch(
            globalActionCreator.toggleToast(
              true,
              translate('您输入的账号密码有误，请重新输入'),
            ),
          )
          break
        case '10811410040994':
          dispatch(
            globalActionCreator.toggleToast(true, translate('账号不存在')),
          )
          break
        default:
          dispatch(globalActionCreator.toggleToast(true, errMsg))
          break
      }
    }
  }
}

const __setAccessToken = (accessToken) => ({
  type: actions.SET_ACCESS_TOKEN,
  accessToken,
})

// 短信登录
export const goSMSLogin = (phoneNumber, captcha) => {
  return async (dispatch, getState) => {
    const state = getState()
    // const riskFlowId = state.getIn(['login', 'operationId'])
    const saLogger = Logger.getInstance()
    dispatch(globalActionCreator.toggleLoading(true))
    const postData = {
      account: phoneNumber,
      otp: captcha,
      // operationId: riskFlowId,
    }
    const showBindProtocol = state.getIn(['main', 'showBindProtocol'])
    const agreeBindProtocol = state.getIn(['login', 'agreeBindProtocol'])
    if (showBindProtocol) postData.agreeBindProtocol = agreeBindProtocol
    const smDeviceData = await getSmDeviceData()
    saLogger.click({
      ...loggerConfig.login_security_check,
      Aku_operation_id: SECURITY_VERIFICATION_BIZ_TYPE.SMS_LOGIN,
    })
    // 安全校验
    const { operationId } = await asyncSecurityCheck({
      bizType: SECURITY_VERIFICATION_BIZ_TYPE.SMS_LOGIN,
      extraParams: {
        phone: phoneNumber,
        platformId: helpers.URL.getParam('appId'),
        partnerPhone: phoneNumber,
      },
      phoneNumber,
      saParams: {
        pageId: 'openpay01',
        pageName: 'opaypay-login page',
      },
      apiBaseURL: getApiBaseUrl('/capi'),
      onRejected: () => {
        saLogger.click({
          ...loggerConfig.login_security_check,
          Aku_operation_id: SECURITY_VERIFICATION_BIZ_TYPE.SMS_LOGIN,
          return_code: 2,
        })
      },
      onSuccess: () => {
        saLogger.click({
          ...loggerConfig.login_security_check,
          Aku_operation_id: SECURITY_VERIFICATION_BIZ_TYPE.SMS_LOGIN,
          return_code: 1,
        })
      },
    })
    if (!operationId) return dispatch(globalActionCreator.toggleLoading(false))
    let {
      data: { data, success, errCode, errMsg },
    } = await axios.post(
      '/capi/openpay/v2/public/client/user/captcha/login',
      {
        smDeviceData,
        ...postData,
        operationId,
        orderId: helpers.URL.getParam('orderId') || '',
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )

    dispatch(globalActionCreator.toggleLoading(false))

    // const loggerInfo = success
    //   ? { Aku_buttonStatus: 1 }
    //   : { Aku_buttonStatus: 0 }
    saLogger.onPageClick({
      ...loggerConfig.login,
      // ...loggerInfo,
      code: success ? '200' : errCode,
      Aku_msg: errMsg,
      Aku_channelName: 'sms',
    })
    if (success) {
      setSecurityCacheData({
        loginType: data?.loginType,
        loginTime: data?.loginTime,
        riskSign: data?.riskSign,
      })
      //#region opt 登录成功后清除保存的密码，防止切换用户的使用的上一个用户的密码
      //https://www.tapd.cn/62870105/bugtrace/bugs/view?bug_id=1162870105001075709
      storage.removeSession('privateKey')
      //#endregion

      data.phoneNumber = phoneNumber
      // 登录成功上报设备信息
      dispatch(
        globalActionCreator.globalSendDeviceData({
          scenario_number: SCENARIO_NUMBER_MAP.LOGIN,
        }),
      )
      // if (data.needSetPassword) {
      // hasNext 是否存在下一步操作： 1 存在，
      if (data && data.hasNext) {
        dispatch(goSetDefaultPhoneNumber(phoneNumber))
        dispatch(goToggleSetPasswordDialog(true))
        dispatch(__setAccessToken(data.accessToken))
      } else {
        const timeStamp = new Date().getTime()
        storage.setSession('smsLoginTimestamp', timeStamp)
        dispatch(
          __performAfterLogin({
            phoneNumber,
            authStatus: data.authStatus,
            loginMethodToken: data.loginMethodToken,
            uid: data.uid,
          }),
        )
      }
    } else {
      dispatch(globalActionCreator.toggleToast(true, `${errMsg}(${errCode})`))
    }
  }
}

/*
 * 设置密码
 * password {string} 用户设置的密码
 */
export const goSetPassword = (password, curPeriod, callbackPageUrl) => {
  password = md5(password)

  return async (dispatch, getState) => {
    const state = getState()
    const accessToken = state.getIn(['login', 'accessToken'])
    const phoneNumber = state.getIn(['login', 'defaultPhoneNumber'])

    dispatch(globalActionCreator.toggleLoading(true))

    const postData = {
      password,
      accessToken,
      account: phoneNumber,
    }
    //region FIXME: 存疑 短信验证码注册也需要？待确定
    const agreeBindProtocol = state.getIn(['login', 'agreeBindProtocol'])
    const showBindProtocol = state.getIn(['main', 'showBindProtocol'])
    if (showBindProtocol) {
      postData.agreeBindProtocol = agreeBindProtocol
    }
    //endregion
    const smDeviceData = await getSmDeviceData()
    const { data: { data, success, errCode, errMsg } = {} } = await axios.post(
      // '/installment/api/json/user/register/pwd/set.do',
      '/capi/openpay/v2/public/client/user/register',
      {
        smDeviceData,
        ...postData,
        orderId: helpers.URL.getParam('orderId') || '',
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    dispatch(globalActionCreator.toggleLoading(false))

    const saLogger = Logger.getInstance()
    saLogger.onPageClick({
      ...loggerConfig.set_password_click,
      code: errCode,
      Aku_msg: errMsg,
    })
    if (success) {
      setSecurityCacheData({
        loginType: data?.loginType,
        loginTime: data?.loginTime,
        riskSign: data?.riskSign,
      })

      dispatch(__savePrivateKey(password))

      dispatch(goToggleSetPasswordDialog(false))

      dispatch(
        __performAfterLogin({
          phoneNumber,
          authStatus: data.authStatus, // 授信状态聚合
          loginMethodToken: data.loginMethodToken, // 登录token
          uid: data.uid,
        }),
      )

      saLogger.onPageClick(
        elementId.SET_NEW_PWD_SUBMIT,
        elementId.STATUS_SET_NEW_PWD_PASS,
      )
    } else {
      saLogger.onPageClick(
        elementId.SET_NEW_PWD_SUBMIT,
        elementId.STATUS_SET_NEW_PWD_FAILED,
      )
      switch (errCode) {
        default:
          dispatch(globalActionCreator.toggleToast(true, errMsg))
          break
      }
    }
  }
}

/*
 * 检查是否是BL的绑定用户，如果是则直接免登录
 * token {string} BL绑定用户携带的token
 */
export const goCheckIfFromBL = (token, appId, refNo, sign) => {
  return async (dispatch) => {
    dispatch(globalActionCreator.toggleLoading(true))
    const { data } = await axios.post('/snowflake/api/json/openpay/autologin', {
      token,
    })
    dispatch(globalActionCreator.toggleLoading(false))

    if (data.errCode === 'OPENPAY.1001') {
      //setLocalData
      location.href = '/v2/bl_safty.html'
    } else if (data.errCode === 'OPENPAY.1002') {
      //setLocalData
      location.href = '/v2/bl_login.html'
    } else if (data.data && data.data.uid) {
      dispatch(
        push({
          pathname: './orderDetails',
        }),
      )
    } else {
      goGetInstallmentInfo(appId, refNo, sign)
    }
  }
}

/*
 * 获取埋点订单信息
 */
export const getOrderInfo = () => {
  return async (dispatch) => {
    const {
      data: { success, data },
    } = await axios.post(`/capi/openpay/v2/public/client/get/home/info`)

    if (!(success && data)) {
      return
    }
    dispatch({
      type: actions.SET_SHOW_POST_TOKEN_PROTOCAL,
      showPostTokenProtocol: data.showPostTokenProtocol,
    })
    if (data.buried) {
      const { orderStatus, paymentGateway } = data.buried
      // 临时保存一些埋点透传数据
      storage.setSession('Aku_orderStatus', orderStatus)
      storage.setSession('gateway', paymentGateway)
    }
  }
}

/*
 * 获取banner配置
 */
export const getBannerConfig = (appId, refNo, sign) => {
  return async (dispatch) => {
    const {
      data: { success, data },
    } = await axios.post(
      '/capi/openpay/public/home/activity/banner',
      {
        pageType: __isPc() ? 2 : 1,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )

    if (success) {
      const { type, activityBanners } = data
      dispatch(goSetCurrentPage(type))
      dispatch(__setFirstPageBanner(activityBanners))
    }
  }
}

//保存用户登录密码到session，下单接口需要传
const __savePrivateKey = (key) => {
  return () => {
    storage.setSession('privateKey', key)
  }
}

// 详情页安全组件登录态失效退回页面需要提示
export const goSetNeedLogoutToast = (needLogoutToast) => ({
  type: actions.SET_NEED_LOGOUT_TOAST,
  needLogoutToast,
})

// 设置当前页面类型
export const goSetCurrentPage = (currentPage) => ({
  type: actions.SET_CURRENT_PAGE,
  currentPage,
})

// 设置订单金额
const __setPayAmount = (payAmount) => ({
  type: actions.SET_PAY_AMOUNT,
  payAmount,
})

// 设置广告标签内容
const __setAdTagContent = (adTagContent) => ({
  type: actions.SET_AD_TAG_CONTENT,
  adTagContent,
})

// 保存分期列表信息
const __setInstallmentList = (installmentList) => ({
  type: actions.SET_INSTALLMENT_LIST,
  installmentList,
})

// 是否展示分期计划
const __setShowInstallmentPlan = (showInstallmentPlan) => ({
  type: actions.SET_SHOW_INSTALLMENT_PLAN,
  showInstallmentPlan,
})

// 设置分期弹窗visible
export const setInstallmentVisible = (installmentVisible) => ({
  type: actions.SET_INSTALLMENT_VISIBLE,
  installmentVisible,
})

// 选择分期弹窗的某个分期
export const goChooseCurPeriod = (period) => {
  return async (dispatch) => {
    dispatch(__setCurPeriod(period))
    dispatch(setInstallmentVisible(false))

    const saLogger = Logger.getInstance()
    saLogger.onPageClick({
      ...loggerConfig.click_installment_option,
      skucreditTerm: period,
    })
  }
}

// 设置登录类型
export const goSetLoginType = (loginType) => ({
  type: actions.SET_LOGIN_TYPE,
  loginType,
})

// 设置当前手机号 初始情况下为默认手机号 从接口获取的 后面就是用户自己填的了
export const goSetDefaultPhoneNumber = (defaultPhoneNumber) => ({
  type: actions.SET_DEFAULT_PHONE_NUMBER,
  defaultPhoneNumber,
})

/**
 * 发送验证码
 * phoneNumber 手机号
 * securityCheck 默认false 是否经过安全组件校验
 * */
export const goSendCodeWithPhoneNumber = ({
  phoneNumber,
  imageCaptcha,
  securityCheck = false,
}) => {
  return async (dispatch, getState) => {
    const state = getState()
    const saLogger = Logger.getInstance()
    dispatch(globalActionCreator.toggleLoading(true))
    let postData = {
      phoneNumber,
      account: phoneNumber,
      // captchaType: 12,
      // userSource: 2
    }

    // 如果通过了校验 就把operationId 带上
    if (securityCheck) {
      postData.operationId = state.getIn(['login', 'operationId'])
    }

    // 如果没安全组件校验 就弹出安全组件
    if (!securityCheck) {
      dispatch(setSecurityVerificationTempParams(postData))
      dispatch(toggleSecurityVerification(true))
      saLogger.click({
        ...loggerConfig.otp_security_check,
        Aku_operation_id: SECURITY_VERIFICATION_BIZ_TYPE.REGISTER,
      })
      // 上报设备信息
      dispatch(
        globalActionCreator.globalSendDeviceData({
          scenario_number: SCENARIO_NUMBER_MAP.LOGIN_SECURITY_CHECK,
        }),
      )

      dispatch(
        setSecurityVerificationCaller(
          SECURITY_VERIFICATION_CALL_METHOD.CAPTCHA,
        ),
      )
      dispatch(globalActionCreator.toggleLoading(false))
      return
    }
    const {
      data: { success, errCode, errMsg },
    } = await axios.post(
      '/capi/openpay/v2/public/client/login/captcha',
      {
        ...postData,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    dispatch(globalActionCreator.toggleLoading(false))

    if (success) {
      // 是否已经成功发送短信验证码
      dispatch(setSendSMSCode(true))
    } else {
      const translate = Translate.getInstance().translate
      // const isGraphicShow = state.getIn(['register', 'showGraphicDialog'])
      dispatch(__changeSendStatus(false))
      //重新获取图形验证码
      // dispatch(goGetGraphicCaptcha())

      switch (errCode) {
        //需要填写图形验证码
        // case 'PROFILE.0054':
        //   dispatch(goToggleGraphicDialog(true))
        //   break

        //验证码错误
        // case 'PROFILE.0003':
        //   isGraphicShow
        //     ? dispatch(
        //         goSetGraphicErrorText(translate('验证码错误，请重新输入'))
        //       )
        //     : dispatch(
        //         globalActionCreator.toggleToast(
        //           true,
        //           translate('验证码错误，请重新输入')
        //         )
        //       )
        //   break

        //手机号错误
        case 'PROFILE.0002':
          dispatch(
            globalActionCreator.toggleToast(
              true,
              translate('请输入正确的手机号码'),
            ),
          )
          break

        //被拉入黑名单
        case 'CAPTCHA.0001':
          // dispatch(goToggleGraphicDialog(false))
          dispatch(
            globalActionCreator.toggleAlertDialog(true, {
              title: translate('提示'),
              content: errMsg,
              footer: translate('我知道了'),
            }),
          )
          break

        default:
          dispatch(globalActionCreator.toggleToast(true, errMsg))
          break
      }

      imageCaptcha &&
        saLogger.onPageClick(
          elementId.CLICK_GRAPHIC_SUBMIT,
          elementId.STATUS_GRAPHIC_FAILED,
          elementId.PAGE_REGISTER,
        )
    }
  }
}

/*
 * 显示设置密码弹框
 * show {bool} true代表显示，false代表隐藏
 */
export const goToggleSetPasswordDialog = (show) => ({
  type: actions.TOGGLE_SET_PASSWORD_DIALOG,
  show,
})

/*
 * 改变验证码发送状态
 * sent {boolean} true代表验证码已发送，false代表未发送
 */
const __changeSendStatus = (sent) => ({
  type: actions.CHANGE_CODE_STATUS,
  sent,
})

export const goSetCurValidPhoneNumber = (validPhoneNumber) => ({
  type: actions.SET_CUR_VALID_PHONE_NUMBER,
  validPhoneNumber,
})

/**
 * 显示/隐藏安全组件
 * */

export const toggleSecurityVerification = (securityVerificationVisible) => ({
  type: actions.TOGGLE_SECURITY_VERIFICATION,
  securityVerificationVisible,
})

/**
 * 设置operationId
 * */

export const setOperationId = (operationId) => ({
  type: actions.SET_OPERATION_ID,
  operationId,
})

/**
 * 设置安全组件临时参数
 * */

export const setSecurityVerificationTempParams = (
  securityVerificationTempParams,
) => ({
  type: actions.SET_SECURITY_VERIFICATION_TEMP_PARAMS,
  securityVerificationTempParams,
})

/**
 * 设置安全组件调起方式
 * */

export const setSecurityVerificationCaller = (securityVerificationCaller) => ({
  type: actions.SET_SECURITY_VERIFICATION_CALLER,
  securityVerificationCaller,
})

/**
 * 设置是否发送了短信验证码
 * */

export const setSendSMSCode = (sendSMSCode) => ({
  type: actions.SET_SEND_SMS_CODE,
  sendSMSCode,
})

/**
 *
 * @param {number} authStatus 授信状态聚合：1 授信成功，2 未授信，3 审核中，4 授信拒绝，5 虚拟额度
 * @param {string} loginMethodToken 登录token，解决新系统下单后需要再次登录问题
 * @param {number} uid 用户uid
 *
 */
const __performAfterLogin = ({
  phoneNumber,
  password,
  authStatus,
  loginMethodToken,
  uid,
}) => {
  const saLogger = Logger.getInstance()

  return async (dispatch, getState) => {
    const state = getState()
    const curPeriod = state.getIn(['login', 'curPeriod'])
    const callbackPageUrl = state.getIn(['login', 'callbackPageUrl'])
    // const userInfo = await akulaku.updateUserInfo()
    // userInfo && saLogger.setUid(userInfo.uid)
    // userInfo && saLogger.login(userInfo.uid)
    // const userInfo = await akulaku.updateUserInfo()
    uid && saLogger.setUid(uid)
    uid && saLogger.login(uid)
    password && dispatch(__savePrivateKey(md5(password)))

    dispatch(globalActionCreator.toggleLoading(false))
    const creditStatusMapUrl = {
      [AUTH_STATUS.CREDIT_STATUS_APPLIED]: './orderDetails',
      [AUTH_STATUS.REGISTER_NO_CREDIT]: './applylimit-instruction',
      [AUTH_STATUS.CREDIT_STATUS_APPLING]: './approvalResult',
      [AUTH_STATUS.CREDIT_STATUS_REJECTED]: './approvalResult',
    }
    const targetPath =
      creditStatusMapUrl[authStatus] || './applylimit-instruction'
    //#region Alipay+ 特殊逻辑，进行拉单请求
    const isAlipay = URL.param('isAlipay')
    if (isAlipay === '1' && authStatus === AUTH_STATUS.CREDIT_STATUS_APPLIED) {
      dispatch(globalActionCreator.toggleLoading(true))
      await fetchAlipayPlusOrder({
        dispatch,
        actionCreator: mainActionCreator,
        onError() {
          const { translate } = Translate.getInstance()
          let config = {
            title: translate('创建订单失败'),
            content: translate(
              '请返回商户重新下单，如果多次创建订单失败请联系客服电话1500920',
            ),
            btnText: translate('返回商户'),
          }
          dispatch(
            globalActionCreator.toggleAlertDialog(true, {
              title: config.title,
              content: config.content,
              footer: [
                {
                  text: config.btnText,
                  onPress: () => {
                    history.goBack()
                  },
                },
              ],
            }),
          )
        },
      })
      dispatch(globalActionCreator.toggleLoading(true))
      return
    }
    //#endregion

    history.push({
      pathname: targetPath,
      state: { curPeriod, callbackPageUrl },
      search: addQueryToUrl(window.location.search, {
        phone: phoneNumber,
        loginMethodToken,
        creditStatus: authStatus,
      }),
    })
  }
}

/**
 * 是否同意绑定协议
 * */
export const goSetAgreeBindProtocol = (agreeBindProtocol) => ({
  type: actions.SET_AGREE_BIND_PROTOCOL,
  agreeBindProtocol,
})

/**
 * 是否展示绑定协议
 * */
export const goSetShowBindProtocol = (showBindProtocol) => ({
  type: actions.SET_SHOW_BIND_PROTOCOL,
  showBindProtocol,
})

/**
 * 是否可以修改手机号
 * */
export const goSetCanModifyPhoneNumber = (canModifyPhoneNumber) => ({
  type: actions.SET_CAN_MODIFY_PHONE_NUMBER,
  canModifyPhoneNumber,
})
