import axios from 'axios'
import _ from 'lodash'
import { history } from 'umi'
import { urlQuery } from '~/common/urlQuery'

interface Res {
  appId: number
  refNo: string
  orderId: number
  loginMethodToken: string
  sign: string
}

interface Params {
  dispatch: AnyFunction
  actionCreator: { goInitBasicOrderInfo: AnyFunction }
  onError?: AnyFunction
}
export async function fetchAlipayPlusOrder(params: Params) {
  const {
    dispatch,
    actionCreator,
    // pc 端有自己的处理流程 这是h5的流程
    onError = () => {
      history.replace({
        pathname: './CreateOrderResult',
        search: history.location.search,
      })
    },
  } = params
  const log = (...args: any) => console.log('[fetchAlipayPlusOrder]: ', ...args)

  // search may be like
  // ?appId=%s&refNo=%s&sign=%s&lang=id&orderId=%s&codeValue=%s%isAlipay=1
  const query = urlQuery.parse<{
    orderId: string
    codeValue: string
  }>(history.location.search)
  log('query %o', query)
  if (!query?.orderId || !query?.codeValue) {
    log('query is invalid', query)
    return
  }
  /**
   * 接口地址
   * http://testyapi.akulaku.com/project/1523/interface/api/227048
   */
  const { data } = await axios.get(`/capi/openpay/v2/alipay/order/fetch`, {
    params: query,
  })
  if (!data.success) {
    log('fetch order failed', data)
    onError()
    return
  }
  const res = data.data as Res
  log('fetch order success', data)

  log(`需要更新main store 存储的 refNo sign`)
  dispatch(
    actionCreator.goInitBasicOrderInfo({
      appId: res.appId,
      refNo: res.refNo,
      sign: res.sign,
    }),
  )

  history.replace({
    pathname: './orderDetails',
    search: urlQuery.addQueryToUrl(history.location.search, _.pickBy(res)),
  })
}
